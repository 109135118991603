<template>
  <b-modal
    size="xl"
    :id="modalId"
    :ref="modalId"
    hide-footer
    :title="title"
    class="clickable"
  >
      <div class="clickable" @click="closeThis()">

  <!--CONTENT-->


    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ornare
      auctor aliquam. Praesent eget porttitor enim, sed convallis quam. Praesent
      et nibh finibus, interdum dui a, consequat justo. Nullam dui urna,
      condimentum a orci at, sollicitudin iaculis mi. Aliquam quis eros
      eleifend, lobortis risus eget, pulvinar augue. Proin blandit arcu tortor,
      et scelerisque odio egestas vitae. Praesent dapibus porttitor auctor.
      Donec rutrum nisi mollis, scelerisque ante vitae, bibendum eros. Donec
      justo nisi, porttitor nec nibh nec, hendrerit malesuada mi.
    </p>

    <p>
      Curabitur fermentum lorem eu purus tempor hendrerit. Praesent a eros
      feugiat, fermentum quam at, suscipit ex. Aenean dignissim justo a risus
      tempor, id fringilla erat pulvinar. Nullam leo enim, faucibus vel mauris
      quis, ultrices rhoncus augue. Sed a fermentum magna. Phasellus viverra
      risus nibh, sit amet mollis felis rutrum dignissim. Vivamus vitae nulla in
      nulla euismod auctor ac ac enim.
    </p>

    <p>
      Curabitur et dolor nisi. Mauris elementum ex in sollicitudin vehicula. Ut
      fringilla vehicula nisl a pulvinar. Vestibulum quis massa eget tellus
      varius sagittis rutrum eu justo. Interdum et malesuada fames ac ante ipsum
      primis in faucibus. Cras a aliquet massa. Cras maximus varius risus, non
      imperdiet nisi finibus vel. Fusce sollicitudin, dui vitae molestie semper,
      mauris ante lobortis mauris, et molestie lectus felis eget justo. In nec
      ante at lectus semper euismod. Pellentesque semper diam id lacinia
      porttitor. Pellentesque sit amet feugiat mauris.
    </p>

    <p>
      Phasellus ac dictum urna. Aenean molestie leo sit amet lectus rhoncus
      feugiat. Pellentesque viverra eu erat nec laoreet. Donec luctus ultrices
      quam, vitae blandit libero feugiat nec. Donec et efficitur arcu. Ut
      rhoncus lacinia dictum. Sed eget leo et lacus placerat malesuada. Nam
      porttitor turpis a tellus pharetra mollis. Integer a finibus mi, vel
      scelerisque neque. Nullam eget pretium elit. Suspendisse ut diam mattis,
      dignissim purus a, dictum ipsum. Duis convallis massa ac quam vulputate
      tempor. Quisque fermentum sem eget venenatis blandit. Donec dictum vitae
      velit finibus tincidunt. Nunc ut interdum odio. Nam sollicitudin lorem a
      nisi efficitur, in accumsan ante scelerisque.
    </p>

    <p>
      Integer id maximus purus. Ut aliquet luctus enim, id congue tortor tempus
      a. Nam mollis, ipsum quis maximus malesuada, nunc libero condimentum
      ipsum, sit amet ullamcorper dui risus ornare felis. Ut at mollis erat.
      Vivamus at libero suscipit, vehicula magna id, pulvinar dui. Sed euismod
      ligula felis, quis commodo nunc posuere id. Fusce blandit sit amet eros a
      imperdiet. Proin lobortis, magna vitae placerat venenatis, neque enim
      dapibus ipsum, eu eleifend ipsum purus semper felis.
    </p>




  <!--END CONTENT-->
      </div>
  </b-modal>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  props: {
    msg: String,
  },
  data() {
    return {
      title: "Política de Privacidade",
      modalId: "privacyPolicy",
    };
  },
  methods: {
    closeThis() {
      this.$root.$emit("bv::toggle::modal", this.modalId);
    },
  },
};
</script>
