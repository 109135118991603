

var options = {
    search_menu: true,
    footer_messages: {
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vitae sapien non mi semper venenatis. Fusce quis erat porta augue viverra rutrum et ac lectus. Sed laoreet ac lacus vel eleifend. Nulla condimentum nisi metus, non fermentum erat porta id. Suspendisse venenatis ornare vulputate.',
        phones_titles: 'Central de atendimento',
        contact_us: 'Entre em contato',
        attendance: 'De Seg a Sex das 8h às 18h.',
        institutional: 'Institucional',
        followus: 'Siga-nos'
    },
    siteLogo: {
        "src": "logo_connect.svg", // with extension
        "svg":  true, // svg = true | png or jpg = false
        "alt": "---" // alt text
    },
    developedBy: {
        copyright: "Auaha - Todos os direitos reservados.",
        msg: "Desenvolvido por",
        link: "https://auaha.com.br"
    },
    whatsapp_options: {
        url: 'https://api.whatsapp.com/send?phone=55',
        class: 'whatsapp-talk',
        icon: 'whatsapp-color'
    },
    main_pages:[ // all icons in SVG format | DO NOT REMOVE NOONE
        {label: 'Home', url: '/', icon: 'home', slug: 'Home', order: -5, hide: true},
        {label: 'Veículos', url: '/veiculos', icon:'', slug: 'Vitrine', order: -4},
        {label: 'Central de atendimento', url: '/contato', icon: '', slug: 'Contact', order: 999},
    ],
    minihomebanner: 'minihomebanner', // slug of mini banner in home.
    datacollect: {
        newsletter: 'newsletter',
    },
    carousel: [ // slug of banners in auahub -- 2 different sizes: more than 768px (bigger) and less than 768px (small) | 
                // if you don't want to use carousel, just leave the array empty
                { slug: 'carousel1-small', size: 'small' },
                { slug: 'carousel1', size: 'bigger' },
    ],
    carousel_sizes: {
        small: { height: '100vh', max_height: '500px' },
        bigger: { height:'100vh', max_height: '600px' },
    }
}


export default function (){
    return options;
    }