


// DO NOT REMOVE, UR FU FOOL!

export default function (){
var spr = `          by Durval sproesser
 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 
@@@@@@@@@@@@@@@@@@@@@@@@@@&@@@@@@@@@@@@@@
@@@@@@@@@@@@@@                  &@@@@@@@@
@@@@@@@@@@@                        @@@@@@
@@@@@@@@@          @@@@@&          (@@@@@
@@@@@@@@          @@@@@@@@          @@@@@
@@@@@@@@                (@@@@@@@@@@@@@@@@
@@@@@@@@@                      @@@@@@@@@@
@@@@@@@@@@&#                      @@@@@@@
@@@@@@@@@@@@@@@@&(                 @@@@@@
@@@@@@@@@@@@&@@@@@@@@@@@           @@@@@@
@@@@@         @@@@@@@@@@@         /@@@@@@
@@@@@@           #@@@@*          &@@@@@@@
@@@@@@@                        /@@@@@@@@@
@@@@@@@@@                   @@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 
    copyright sproesser@2022 . Auaha`

    console.log("%c" + spr, 'background: transparent; color: #3108ff')
}
