var mixin = {
    filters:{
        showCondition: (value) => {
            var x = "Novo";
            if (value == "used") {
               x = "Usado";
            }
            return x;
        },
    }
}

export default mixin;
