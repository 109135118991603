var mixin = {
    filters:{
        showPrice: (value) => {
            if (value != undefined) {
                var price = value.toString()
                price = price.split('.')
                if (price[1] == undefined) { price[1] = "00" }
                if (price[1].length < 2) { price[1] = price[1] + "0" }
                if (price[1].length > 2) { price[1] = price[1].substring(0, 2) }
                price[0] = price[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                return `R$ ${price[0]},${price[1]}`
            }
            return undefined
        },
        showPriceInt: (value) => {
            if (value != undefined) {
                var price = value.toString()
                price = price.split('.')
                price[0] = price[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                return `R$ ${price[0]}`
            }
            return undefined
        },
    }
}

export default mixin;
