import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from "../views/PageNotFound.vue"
import Load from "../views/Load.vue"
import VitrineShow from "../views/VitrineShow.vue"
import options from "@/config/options"

Vue.use(VueRouter)

const routes = [
    { 
      path: "*", 
      name: "PageNotFound",
      component: PageNotFound,
    },
    { 
      path: "/p/*", 
      component: Load,
    },
    { 
      path: "/veiculos/*", 
      component: VitrineShow,
    }
]

//

options().main_pages.forEach(page => {
  routes.push({
    path: `${page.url}`,
    name: page.slug,
    props: true,
    component: () => import(`../views/${page.slug}.vue`)
  })
})

const router = new VueRouter({
  mode: 'history',
  routes,
  props: true
})

export default router
