<template>
    <div class="load-container">
        <div v-html="contentPage"></div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Load',
    props: {
        dataUser: Object,
    },
    data() {
        return {
            metaData: {},
            contentPage: undefined,
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            var p = this.$route.path;
            var pathArray = p.split('/');
            var path = pathArray[pathArray.length - 1];

            var url = `${process.env.VUE_APP_API_URL}/showpages/${process.env.VUE_APP_STORE_ID}/${path}`;
            await axios
            .get(url)
            .then((res) => {
                this.contentPage = res.data;
                var script = this.contentPage.match(/<script>([\s\S]+)<\/script>/);
                if(script != null){
                    var ss = document.createElement('script');
                    ss.innerHTML = script[1];
                    document.body.appendChild(ss);
                }
                this.contentPage = this.contentPage.replace(/<script>([\s\S]+)<\/script>/g, '');
                var meta = this.contentPage.match(/<meta[^>]*>/g);
                if(meta != null){
                    meta.forEach((item) => {
                        var name = item.match(/name="([^"]+)"/);
                        var content = item.match(/content="([^"]+)"/);
                        if(name != null && content != null){
                            if(name[1] == 'title'){
                                this.metaData.title = content[1];
                            }
                            if(name[1] == 'description'){
                                this.metaData.description = content[1];
                            }
                            if(name[1] == 'keywords'){
                                this.metaData.keywords = content[1];
                            }
                        }
                    });
                    this.$emit('changeMeta', this.metaData);
                }
                this.contentPage = this.contentPage.replace(/<meta[^>]*>/g, '');
            })
            .catch((err) => {
                this.contentPage = err;
                this.$router.push('/404');
            });
            
        },
    },
}
</script>