<template>
  <div class="all-window">
    <div>
      <h2>404 - Página não encontrada</h2>
      <h5>Parece que você está meio perdido.</h5>
    </div>
  </div>
</template>

<script>

export default {
  name: "PageNotFound",
};
</script>

