<template>
  <a :class="whatsapp_options.class" :href="`${whatsapp_options.url}${whatsapp}`" target="_blank" v-if="show" >
    <inline-svg v-if="verifyAsset(whatsapp_options.icon)" class="icon" :src="require(`@/assets/svg/${whatsapp_options.icon}.svg`)"></inline-svg>
  </a>
</template>

<script>
import verifyAsset from '@/components/helpers/verifyAsset';

export default {
  mixins: [verifyAsset],
  name: "Whatsapp",
  props: {
    dataUser: Object,
    reloadCounter: Number,
  },
  data() {
    return {
      show: false,
      whatsapp: undefined,
      whatsapp_options: undefined,
    };
  },
  methods: {
    verifyWhatsapp() {
        this.show = false;
        if(this.dataUser.whatsapp_active){
        this.dataUser.meliphones.forEach((phone) => {
            if (phone.linktype == 0) {
                var phone_number = phone.number.replace(/\D/g, '');
                this.whatsapp = phone_number;
                this.show = true;
            }
        });
        }
    },
  },
  watch: {
    reloadCounter: {
      handler() {
        if (this.dataUser != undefined) {
          this.whatsapp_options = this.dataUser.options.whatsapp_options;
          this.verifyWhatsapp()
        }
      },
    },
  },
};
</script>
