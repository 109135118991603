import axios from 'axios'
const Filter = require('./mercadoLivreFilter')

const dotenv = require('dotenv');
dotenv.config();

class MercadoLivreApi {
    constructor(user_id, cat) {
        this.api = user_id
        this.default_cat = cat;
        this.dataResults = {};
        this.req = {
            headers: { 'Content-Type': 'application/json' },
            params: {}
        }
        return this;
    }

    async setNewUser(user_id){
        this.api = user_id;
        return this;
    }

    async products(limit, currentPage, query=undefined, category=this.default_cat, sort='price_asc'){
        limit = parseInt(limit)
        var result = ''
        var lquery = (query != undefined) ? `&q=${query}` : '';
        var lcategory = `&category=${category}`;
        var lsort = `&sort=${sort}`;
        var url = `https://api.mercadolibre.com/sites/MLB/search?status=active&seller_id=${this.api}${lcategory}${lquery}${lsort}`
        this.req.params = {
            limit: limit,
            offset: (currentPage-1) * limit,
          }
        await axios
            .get(url, this.req)
            .then((res) => {
              result = {
                data: Filter.FilterProducts(res.data.results),
                filters: Filter.FilterFilters(res.data.available_filters),
                pagination: {
                    total: res.data.paging.total,
                    pages: Math.ceil(res.data.paging.total / limit),
                },
            }
            })
            .catch((err) => {
              console.log(err)
            });
        this.dataResults = result;
        return result
    }

    async product(productId){
        var result = ''
        var url = `https://api.mercadolibre.com/items?ids=${productId}`
        var urlTxt = `https://api.mercadolibre.com/items/${productId}/description`
        await axios
            .get(url, this.req)
            .then((res) => {
              result = Filter.FilterProduct(res.data[0].body)
            })
            .catch((err) => {
              console.log(err)
            });
        await axios
            .get(urlTxt, this.req)
            .then((res) => {
              result.description = res.data.plain_text;
              result.showdesc = true;
            })
            .catch(() => {
              result.description = "Descrição não encontrada";
              result.showdesc = false;
            });
        return result
    }
    
    async categories(category=this.default_cat){
        var result = ''
        var lcategory = `&category=${category}`;
        const url = `https://api.mercadolibre.com/sites/MLB/search?seller_id=${this.api}${lcategory}`
        await axios
            .get(url, this.req)
            .then((res) => {
              var data = []
              res.data.results.forEach(e=>{
                if (e.category_id != undefined)  { data.push(e.category_id) }
              })
              result = data
            })
            .catch((err) => {
                console.log(err)
            });
            return result
        }

      getFilters(title = undefined, name = undefined){
        var data = []
        if(title == undefined || name == undefined){
          var array = [
            {title: "Marcas", name: 'BRAND'},
            {title: "Ano", name: 'VEHICLE_YEAR'},
            {title: "Modelos", name: 'MODEL'},
            //{title: "Preço", name: 'price'},
            //{title: "Quilômetros", name: 'KILOMETERS'},
            ]
          array.forEach(e => {
            data.push({
              id: e.name.toLowerCase(),
              title: e.title,
              items: Filter.FilterTypes(this.dataResults.filters, e.name)
            })
          } )
        } else {
            data.push({
              id: name.toLowerCase(),
              title: title,
              items: Filter.FilterTypes(this.dataResults.filters, name)
            })
        }
        return data
    }

}

export default MercadoLivreApi;