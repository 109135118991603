

var SizeFunctions = {

    Min(val, size = 0) {
        if (val != undefined) {
            var x = val.trim()
            if (x.length <= size) {
                return true;
            }
        }
        return false;
    },

    Max(val, size = 99999) {
        if (val != undefined) {
            var x = val.trim()
            if (x.length >= size) {
                return true;
            }
        }
        return false
    },

    Equal(val, size = 0) {
        if (val != undefined) {
            var x = val.trim()
            if (x.length == size) {
                return true;
            }
        }
        return false;
    }
}


export default SizeFunctions