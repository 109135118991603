
var mixin = {
  methods: {
    verifyAsset(asset) {
      try {
        require(`@/assets/svg/${asset}.svg`);
        return true;
      } catch (e) {
        return false;
      }
    }
  }
}

export default mixin;

