

function FilterFilters(filters){
    var data = []
    filters.forEach(e => {
        var v = [];
        e.values.forEach(x => { v.push({"id": x.id, "name": x.name, "results": x.results }) })
        data.push({ "id": e.id, "name": e.name, "values": v, })
    });
    return data
}


function FilterTypes(filter, id){
    var data = undefined
    try{
        filter.forEach(e => {
            if(e.id == id) {
                data = e.values
            }
        });
    } catch(e){
        return data
    }
    return data
}


function FilterProducts(data){
    let products = data.map((product) => ({
        id: product.id,
        categoryId: product.category_id,
        title: product.title,
        trim: product.trim,
        price: product.price,
        condition: product.condition,
        thumbnail: renameThumb(product.thumbnail),
        attributes: filterAttributes(product.attributes),
        year: filterAttribute(product.attributes, 'Ano'),
        km: filterAttribute(product.attributes, 'Quilômetros'),
    }))
    return products
}


function FilterProduct(product){
    let data = {
        id: product.id,
        title: product.title,
        trim: product.trim,
        categoryId: product.category_id,
        attributes: filterAttributes(product.attributes),
        details: filterArray(product.attributes, ['BRAND', 'MODEL', 'VEHICLE_YEAR', 'COLOR', 'FUEL_TYPE', 'DOORS', 'TRANSMISSION', 'KILOMETERS', 'STEERING', 'PASSENGER_CAPACITY' ]),
        more_info: filterArray(product.attributes, ['DISTANCE_BETWEEN_AXES ', 'LENGTH ', 'GEAR_NUMBER', 'POWER', 'HEIGHT', 'WIDTH', 'ENGINE_DISPLACEMENT', 'ENGINE', 'VALVES_PER_CYLINDER', "TRACTION_CONTROL" ]),
        general_info: filterStart(product.attributes, 'HAS_'),
        price: product.price,
        condition: product.condition,
        location: {
            address: product.location.address_line,
            city: product.location.city.name,
            state: product.location.state.name,
            zipcode: product.location.zip_code,
        },
        permalink: product.permalink,
        thumbnail: renameThumb(product.thumbnail),
        images: filterPictures(product.pictures),
        video_id: product.video_id,
        status: product.status,
        year: filterAttribute(product.attributes, 'Ano'),
        km: filterAttribute(product.attributes, 'Quilômetros'),
    }
    return data
}

function filterAttribute(attributes, name){
    var data = attributes.find(function(element) {
            return element.name == name;
    });
    return data.value_name;
}

function filterAttributes(attributes){
    var data = []
    attributes.forEach(e => {
        if(e.value_name != "Não" && e.value_name != "") {
            data.push({ "id": e.id, "name": e.name, "value": e.value_name, })
        }
    });
    data.sort(function(a, b) {
        return a.value.localeCompare(b.value);
    });
    return data
}

function filterStart(attributes, string){
    var data = []
    attributes.forEach(e => {
        if(e.id.startsWith(string)) {
            data.push({ "id": e.id, "name": e.name, "value": e.value_name, })
        }
    });
    data.sort(function(a, b) { return a.name.localeCompare(b.name); });
    return data
}

function filterArray(attributes, array){
    var arrayToSearch = array
    var data = []
    attributes.forEach(e => {
        if(arrayToSearch.includes(e.id)) {
            data.push({ "id": e.id, "name": e.name, "value": e.value_name, })
        }
    });
    data.sort(function(a, b) { return a.name.localeCompare(b.name); });
    return data
}

function filterPictures(pictures){
    var data = []
    pictures.forEach(e => {
        data.push({ "image": e.url, })
    });
    return data
}

function renameThumb(thumb){
    let thumb_name = thumb.split('.')
    let thumb_ext = thumb_name.pop()
    thumb_name = thumb_name.join('.')
    thumb_name = thumb_name.substring(0, thumb_name.length - 1) + 'O'
    return thumb_name + '.' + thumb_ext
}


module.exports = {FilterProducts, FilterProduct, FilterFilters, FilterTypes};