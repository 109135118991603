<template>
    <div class="form">
      <div v-if="!emailSent">
        <div class="form-group">
            <b-form-input type="text" :state="StateName" trim v-model="name" placeholder="Nome"></b-form-input> 
        </div>
        <div class="form-group">
            <b-form-input type="email" :state="StateEmail" trim v-model="email" placeholder="Email"></b-form-input> 
        </div>
        <div class="form-group">
            <b-form-textarea :state="StateMessage" trim v-model="message" placeholder="Mensagem"></b-form-textarea>
        </div>
        <div class="actions">
            <b-spinner v-if="sending" label="Busy"></b-spinner>
            <div v-else >
            <button @click="sendEmail()" :disabled="StatelAll" class="btn send mr-2"> Enviar</button>
            <button @click="cancel()" class="btn cancel"> Cancelar</button>
            </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-success text-center p-3 clickable" @click="clearForm()">
            Obrigado por entrar em contato!
        </div>
      </div>  
    </div>
</template>


<script>
import LengthValidator from "@/components/helpers/lengthValidator"
import EmailValidator from "@/components/helpers/emailValidator"
import axios from "axios";

export default {
  name: "Interest",
  props: {
    product: Object,
  },
  created() {
    this.verifyLocalStorage()
  },
  data() {
    return {
      name: undefined,
      email: undefined,
      message: undefined,
      sending: false,
      emailSent: false,
    };
  },
  methods:{
    async sendEmail(){
      this.sending= true;
      if(this.StatelAll == false){
        var data = {name: this.name, email: this.email };
        localStorage.setItem('interestData', JSON.stringify(data) );
        var aboutProduct = `Info sobre: ${this.product.title} - ID: ${this.product.id}<br><br>` 
        var emailData = {
          name: this.name,
          email: this.email,
          subject: `Quero informações sobre ${this.product.title}`,
          message: aboutProduct + this.message,
          store_id: process.env.VUE_APP_STORE_ID
        }
        var headers = { 'Content-Type': 'application/json' }
        await axios.post(`${process.env.VUE_APP_API_URL}/sendmail`, emailData, headers).then(() => {
          this.emailSent = true;
          this.sending= false;
        }).catch(() => {
          this.emailSent = false;
          this.sending= false;
        });
      }
    },
    verifyLocalStorage(){
      this.emailSent = false;
      this.sending= false;
      if(localStorage.getItem('interestData')){
        var data = JSON.parse(localStorage.getItem('interestData'));
        this.name = data.name;
        this.email = data.email;
      }
    },
    clearForm(){
      this.emailSent= false;
      this.sending= false;
      this.message = undefined;
    },
    cancel(){
      this.clearForm();
      this.$emit("close");
    },
  },
  computed: {
    StateName() {
      return LengthValidator.Max(this.name, 3)
    },
    StateEmail() {
      return EmailValidator(this.email)
    },
    StateMessage() {
      return LengthValidator.Max(this.message, 10)
    },
    StatelAll(){
        return !(this.StateName && this.StateEmail && this.StateMessage)
    }
  },
};
</script>
