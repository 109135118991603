<template>
  <div id="footer_container">
  <PrivacyPolicy />
    <div id="footer">
      <div class="site_limit">
        <div id="one" v-if="logo != undefined">
          <inline-svg v-if="logo.svg" class="logo" :src="require(`@/assets/svg/${logo.src}`)" :alt="logo.alt"></inline-svg>
          <img v-else class="logo" :src="require(`@/assets/img/${logo.src}`)" :alt="logo.alt"> 
          <span v-if="footer_messages != undefined">{{ footer_messages.text }}</span>
        </div>
        <div id="two">
          <span v-if="footer_messages != undefined" class="strong">{{ footer_messages.phones_titles }}</span>
          <div class="phonegrid" v-if="phones != undefined">
              <div class="phone" v-for="phone, index in phones" :key="`p${index}`">
                <a class="link" :href="(phone.linktype == 1) 
                          ? `tel:${removeNonNumbers(phone.number)}` 
                          : `${whatsappLink}${removeNonNumbers(phone.number)}`"
                          :target="(phone.linktype == 1) ? '_self' : '_blank'">
                <inline-svg v-if="verifyAsset(phone.icon)" class="icon" :src="require(`@/assets/svg/${phone.icon}.svg`)"></inline-svg>
                {{ phone.number }} </a>
              </div>
          </div>
          <span v-if="footer_messages != undefined" class="small">{{ footer_messages.attendance }}</span>
        </div>
        <div id="three">
          <span v-if="footer_messages != undefined" class="strong">{{ footer_messages.institutional }}</span>
            <div v-if="menuItems != undefined" class="items">
              <span class="text-left" v-for="item, i in menuItems" :key="i">
                <a :class="($route.path == item.url) ? 'active' : ''" v-if="!item.hide"
                            :target="(item.target != undefined) ? item.target : '_self'"
                            :href="($route.path == item.url) ? '' : item.url"
                            v-b-modal="(item.modal != undefined) ? item.modal : ''">
                  {{ item.label }}
                </a>
              </span> 
            </div>
        </div>
        <div id="four">
          <span v-if="footer_messages != undefined" class="strong">{{ footer_messages.followus }}</span>
            <div class="socialgrid" v-if="networks != undefined">
                <div class="social" v-for="network, index in networks" :key="`n${index}`">
                  <a class="link" :title="network.label" :href="network.url" target="_blank">
                    <inline-svg v-if="verifyAsset(network.icon)" class="icon" :src="require(`@/assets/svg/${network.icon}.svg`)"></inline-svg> 
                    <span v-else>{{ network.label }}</span>
                  </a>
                </div>
            </div>
        </div>
      </div>
      <div id="copyright" v-if="developedBy != undefined">
        <div class="site_limit">
              <a class="text-decoration-none clickable" :href="developedBy.link" target="_blank">
                {{ appCopyRight }} • {{ appVersion }} - {{ developedBy.copyright }} {{ appName }}
              </a>
              <div>
                <a class="text-decoration-none clickable d-flex" :href="developedBy.link" target="_blank">
                  <span class="mr-2">{{ developedBy.msg }}</span>
                  <inline-svg :src="require(`@/assets/svg/logo_auaha.svg`)" alt="auaha"></inline-svg>
                </a>
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrivacyPolicy from '@/components/privacyPolicy'
import verifyAsset from '@/components/helpers/verifyAsset';

export default {
  name: "Footer",
  mixins: [verifyAsset],
  components: { PrivacyPolicy  },
  props: {
      dataUser: Object,
      reloadCounter: Number
  },
  data() {
    return {
      logo: undefined,
      appName: undefined,
      developedBy: undefined,
      footer_messages: undefined,
      phones: undefined,
      whatsappLink: undefined,
      menuItems: undefined,
      networks: undefined,
      appCopyRight: process.env.VUE_APP_COPYRIGHT,
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
  methods:{
    menuAdd(){
      this.menuItems = [];
      this.dataUser.melimenu.forEach(e => {
        this.menuItems.push(e)
      });
      this.menuItems.push({
        label: 'Política de Privacidade',
        modal: 'privacyPolicy',
      })
    },
    removeNonNumbers(value){
      return value.replace(/\D/g, '')
    }
  },
  watch: {
    reloadCounter: {
        handler() {
          if(this.dataUser != {}){
            this.logo = this.dataUser.options.siteLogo;
            this.appName = this.dataUser.site_name;
            this.developedBy = this.dataUser.options.developedBy;
            this.footer_messages = this.dataUser.options.footer_messages;
            this.phones = this.dataUser.meliphones
            this.whatsappLink = this.dataUser.options.whatsapp_options.url
            this.networks = this.dataUser.melisocialnetwork
            this.menuAdd()
          }
        },
      }
  }
};
</script>

<style lang="scss">
#footer_container {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #FFFFFF;

  a {
    color: #FFFFFF;
  }
}

#footer {
    width: 100%;
    background: var(--s-background-navbar) !important;
    background: #F43A3C;
        
  .site_limit {
    padding-left: 10px !important;
    padding-right: 10px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 20px;
    flex-wrap: wrap;

    @media screen and (max-width: 470px) { flex-direction: column; gap:40px }


    #one, #two, #three, #four {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;

      .items {
        gap: 10px;
      }
    }

    #one {
      gap: 16px;
      @media screen and (min-width: 992px) { max-width: 23%; }
    }

    #four {
      @media screen and (max-width: 470px) { margin: auto; }
      align-items: center;
    }

    .phonegrid, .socialgrid {
        .icon {
          width: 20px;
          height: 20px;
          fill: var(--s-footer-contact-link)
        }

        a {
            text-decoration: none;
            color: var(--s-footer-contact-link);
        }
        a:hover {
            color: var(--s-footer-contact-link-hover);
        }
        
        div { margin-top: 10px; }
        margin-bottom: 10px;
    }

    .items{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap:5px;
    }

    .socialgrid {
      display: flex;
      gap: 10px;

      .icon {
          width: 25px;
          height: 25px;
      }
    }
}
}

#copyright {
  width: 100%;
  min-height: 40.5px;
  background: var(--s-background-copyright) !important;

  .site_limit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  flex-wrap: wrap;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  span {
    line-height: 20px;
  }
  }
}
</style>