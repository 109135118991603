<template>
  <b-navbar sticky toggleable="lg">
    <div class="mc_navbar">
      <div class="site_limit">
        <div class="logo">
        <b-navbar-brand id="logo" href="/" v-if="logo != undefined">
            <inline-svg v-if="logo.svg" :src="require(`@/assets/svg/${logo.src}`)" :alt="logo.alt"></inline-svg>
            <img v-else :src="require(`@/assets/img/${logo.src}`)" :alt="logo.alt"> 
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <inline-svg class="menu_btn" v-if="expanded" :src="require(`@/assets/svg/btn_menu_close.svg`)" alt="menu"></inline-svg>
            <inline-svg class="menu_btn" v-else :src="require(`@/assets/svg/btn_menu.svg`)" alt="menu"></inline-svg>
          </template>
        </b-navbar-toggle>

        </div>
        <div id="list_menu" v-if="menuItems != undefined">
          <b-navbar-nav class="text-left" v-for="item, i in menuItems" :key="i">
            <b-nav-item :class="($route.path == item.url) ? 'active' : ''" v-if="!item.hide"
                        :target="(item.target != undefined) ? item.target : '_self'"
                        :href="($route.path == item.url) ? '' : item.url">
            <inline-svg v-if="verifyAsset(item.icon)" class="logo" :src="require(`@/assets/svg/${item.icon}.svg`)"></inline-svg>
              {{ item.label }}
            </b-nav-item>
          </b-navbar-nav> 
          <div class="clear" id="form_nave_container" v-if="ocultThis === 'naomostraisso'">
            <b-form-input v-on:keyup.enter="letsSearch()" id="form_nave" type="text" v-model="search" trim placeholder="Faça sua busca aqui!" />
            <inline-svg @click="letsSearch()" id="search_icon" :class="(search != undefined) ? 'clickable' : ''" :src="require(`@/assets/svg/icon_search.svg`)" alt="search"></inline-svg>
          </div>
        </div>
      </div>
        <b-collapse id="nav-collapse" class="mr-2"  v-if="menuItems != undefined">
          <b-navbar-nav class="text-left" v-for="item, i in menuItems" :key="i">
            <b-nav-item :class="($route.path == item.url) ? 'active' : ''" v-if="!item.hide"
                        :target="(item.target != undefined) ? item.target : '_self'"
                        :href="($route.path == item.url) ? '' : item.url">
            <inline-svg v-if="verifyAsset(item.icon)" class="logo" :src="require(`@/assets/svg/${item.icon}.svg`)"></inline-svg>
              {{ item.label }}
            </b-nav-item>
          </b-navbar-nav> 
          <div class="clear" id="form_nave_container" v-if="ocultThis === 'naomostraisso'">
            <b-form-input v-on:keyup.enter="letsSearch()" id="form_nave" type="text" v-model="search" trim placeholder="Faça sua busca aqui!" />
            <inline-svg @click="letsSearch()" id="search_icon" :class="(search != undefined) ? 'clickable' : ''" :src="require(`@/assets/svg/icon_search.svg`)" alt="search"></inline-svg>
          </div>
        </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import verifyAsset from '@/components/helpers/verifyAsset';

export default {
  mixins: [verifyAsset],
  name: "Navbar",
  async mounted() {
    window.addEventListener(
      "resize",
      () => {
        this.windWidth = window.innerWidth;
      },
      false
    );
    this.windWidth = window.innerWidth;
    this.showNameCheck(this.windWidth);
  },
  data() {
    return {
      ocultThis: undefined,
      windWidth: 0,
      showName: true,
      menuItems: undefined,
      logo: undefined,
      search: undefined,
    };
  },
  props: {
      dataUser: Object,
      reloadCounter: Number
  },
  methods: {
    letsSearch(){
      if(this.search != undefined){
        this.$router.push({ name: 'Vitrine', params: { search: this.search } });
      }
    },
    showNameCheck(w) {
      this.showName = false;
      if (w < 992) {
        this.showName = true;
      }
    },
  },
  watch: {
    windWidth(w) {
      this.showNameCheck(w);
    },
    reloadCounter: {
      handler() {
        if(this.dataUser != {}){
              this.logo = this.dataUser.options.siteLogo;
              this.menuItems = this.dataUser.melimenu;
              /* if I decide to use .hide = true in menu-options
              this.menuItems.forEach((item) => {
                  if(item.hide){
                      this.menuItems = this.menuItems.filter(i => i.slug != item.slug);
                  }
              });
              */
            }
          }
        },
      }
};
</script>


<style lang="scss">
.menu_btn{
  fill: white;
  width: 30px;
  height: 30px;
}
.mc_navbar {
  width: 100%;
  background: var(--s-background-navbar) !important;

  button {
    position: relative;
    top: -5px;
  }

  .site_limit {
  padding-left: 10px !important; 
  @media screen and (max-width: 470px) { padding-left: 5px; padding-right: 5px; }
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 0;
  height: 72px;
  flex: 1 1 0;
  
  @media screen and (max-width: 991px) {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    padding-top: 10px;
  }
}

  .logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 0;
  height: 72px;
  flex: 1 1 0;
  
}

}

#logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 0px 0px;
  gap: 10px;
  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

#list_menu{
    @media screen and (max-width: 991px) { display: none; }
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 62px;
    height: 48px;
    margin: 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;   
    box-shadow: none;
    border: none; 
}

#nav-collapse {
    @media screen and (max-width: 991px) { display: flex; }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: var(--s-background-navbar);
    width: 100%;
    padding: 0 30px 20px;
    margin-top: -20px;
    border-bottom: var(--s-button-dark) 1px solid;
    box-shadow: var(--s-element-shadown);
    margin-top: 10px;
}

#form_nave_container{
  display: flex;
}

#form_nave {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px 4px 16px;
  gap: 120px;

  width: 260px;
  height: 48px;

  background: #FFFFFF;
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

#search_icon {
    width: 40px;
    height: 40px;
    position: relative;
    right: 45px;
    top: 5px;
}
</style>