<template>
  <div id="app">
    <Navbar :dataUser="dataUser" :reloadCounter="reloadCounter"/>
    <router-view class="content min-all-window" :dataUser="dataUser" :reloadCounter="reloadCounter" @changeMeta="changeMeta"/>
    <Footer :dataUser="dataUser" :reloadCounter="reloadCounter"/>
    <Whatsapp :dataUser="dataUser" :reloadCounter="reloadCounter" />
  </div>
</template>

<script>
import sproesser from "@/components/tools/sproesser";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Whatsapp from "@/components/helpers/whatsapp.vue";
import options from '@/config/options.js'
import axios from 'axios'

export default {
  name: "App",
  components: { Navbar, Footer, Whatsapp },
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        { name: 'robots', content:  'all' },
        { name: 'description', content:  this.pageDescription },
        { name: 'keywords', content:  this.pageKeywords },
        { name: 'author', content:  process.env.VUE_APP_AUTHOR },
        { name: 'publisher', content:  process.env.VUE_APP_PUBLISHER },
        { name: 'og:title', content:  this.pageTitle },
        { name: 'og:image', content:  this.pageImage },
        { name: 'og:url', content:  this.pagePath },
        { name: 'og:type', content:  'website' },
        { name: 'og:description', content:  this.pageDescription },
        { name: 'og:site_name', content:  this.pageTitle },
        { name: 'twitter:card', content:  "summary_large_image" },
      ],
      link: [
        { rel: 'canonical', href: this.pagePath }
      ]
    }
  },
  async created() {
    window.onpopstate = function () {
      location.reload()
    };
    sproesser();
    this.pagePath = window.location.href;
    document.documentElement.setAttribute('lang', 'pt-br')
    var url = `${process.env.VUE_APP_API_URL}/melishop/${process.env.VUE_APP_STORE_ID}/${process.env.VUE_APP_MELIUSER_ID}`;
    await axios
      .get(url)
      .then((res) => {
        this.dataUser = res.data;
        this.pageTitle = (this.dataUser.meta_title != undefined) ? this.dataUser.meta_title : '---';
        this.pageDescription = (this.dataUser.meta_description != undefined) ? this.dataUser.meta_description : '---';
        this.pageKeywords = (this.dataUser.meta_keywords != undefined) ? this.dataUser.meta_keywords : '---';
          this.dataUser.options = options();

          this.dataUser.melimenu.forEach((item) => {
            if (item.url.indexOf('http://') > -1 || item.url.indexOf('https://') > -1 || item.url.indexOf('ftp://') > -1) {
              item.target = '_blank';
            } else if (item.url.indexOf('mailto:') > -1 || item.url.indexOf('tel:') > -1) {
              item.target = '_self';
            } else {
              item.target = '_self';
              item.url = `/p/${item.url}`;
            }
          });
          this.pageImage =  this.pagePath.slice(0, -1) + require(`@/assets/svg/${this.dataUser.options.siteLogo.src}`);

          this.dataUser.melimenu = this.dataUser.melimenu.concat(this.dataUser.options.main_pages);
          this.dataUser.melimenu.sort((a, b) => (a.order > b.order) ? 1 : -1);

          this.reloadCounter++;

      })
      .catch((err) => {
        console.log(err);
        this.dataUser = undefined
      });

  },
  data() {
    return {
      dataUser: {},
      reloadCounter: 0,
      pageTitle: '---',
      pageDescription: '---',
      pageKeywords: '---',
      pagePath: '---',
      pageImage: '---',
    };
  },
  methods:{
    changeMeta(x){
      if(x != undefined){
        if(x.title){ this.pageTitle = x.title; }
        if(x.description){ this.pageDescription = x.description; }
        if(x.keywords){ this.pageKeywords = x.keywords; }
        if(x.image){ this.pageImage = x.image; }
        if(x.addTitle != undefined){ this.pageTitle = `${this.pageTitle} - ${x.addTitle}`; }
        if(x.addDescription != undefined){ this.pageDescription = `${this.pageDescription}. ${x.addDescription}`; }
        if(x.addKeywords != undefined){ this.pageKeywords = `${this.pageKeywords}, ${x.addKeywords}`; }
      }
    },
  }
};
</script>


