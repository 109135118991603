<template>
<div id="c">
  <div class="site_limit">
    <div class="top ">
      <span>
        <a href="/" class="linkHome">Home |</a> <a href="/veiculos" class="linkHome">Veículos</a>
      </span>
    </div>
  <div>
  <div class="site_limit flex" v-if="item != undefined && !loading">

    <b-modal id="showAllImages" v-if="item.images != undefined" hide-footer size="xl" :title="item.title" >
      <b-carousel class="corner" :controls="true" :indicators="true" :interval="4000">
          <b-carousel-slide v-for="(image, index) in item.images" :key="index" :img-src="image.image">
          </b-carousel-slide>
      </b-carousel>
    </b-modal>
    <div class="principal">
      <div class="previews hideMobile" >
        <div class="thumbnails" v-if="item.images != undefined">
          <div v-for="(image, index) in item.images" :key="index" v-show="index+1 <= max_thumbnails">
            <div class="thumbnail clickable" v-if="index+1 <= max_thumbnails" 
              @click="changeImage(image.image)" :style="`background: url('${image.image}');`" >
            </div>
          </div>
          <div class="thumbnail clickable last_thumbnail" v-if="item.images.length > max_thumbnails" 
            v-b-modal.showAllImages :style="`background: url('${item.images[max_thumbnails].image}');`" ><span>+{{ item.images.length - max_thumbnails }}</span>
          </div>
        </div>
        <div class="preview" v-if="showImage != undefined" :style="`background: url('${showImage}');`" ></div>
      </div>
      <b-carousel class="hideDesktop corner" :controls="true" :indicators="true" :interval="4000">
          <b-carousel-slide v-for="(image, index) in item.images" :key="index" :img-src="image.image">
          </b-carousel-slide>
      </b-carousel>
      <div class="hideMobile column">
        <div class="table" v-if="item.details != undefined">
            <div class="table-line" v-for="detail, x in item.details" :key="x" :class="(x%2) ? 'odd' : ''">
              <div class="title">{{ detail.name }}</div>
              <div class="description">{{ detail.value }}</div>
            </div>
        </div>
        <div class="caracteristics" v-if="item.showdesc">
          <div class="title">Principais Características</div>
          <div class="description">{{ item.description }}</div>
        </div>
      </div>
    </div>  
    <div class="site_details">
      <div class="container">
        <div class="title">{{ item.title }}</div>
        <div class="meliid">{{ item.id }}</div>
        <div class="condition">{{item.condition | showCondition }}</div>
        <div class="location">{{item.location.city}}/{{item.location.state}}</div>
        <div class="attributes">
            <div class="year">
                <inline-svg :src="require(`@/assets/svg/detail_year.svg`)" alt="ano"></inline-svg>
                {{ item.year }}
            </div>
            <div class="km">
                <inline-svg :src="require(`@/assets/svg/detail_kilometer.svg`)" alt="ano"></inline-svg>
                {{ item.km | showNumberInt }}
            </div>
        </div>
        <div class="price">{{ item.price | showPriceInt }}</div>
        <div class="actions">
          <b-button @click="ask = true" id="email">Perguntar</b-button>
          <b-button @click="ask = false, openWhatsapp(`${whatsapp_options.url}${whatsapp}`)" id="whatsapp" >
            <inline-svg class="icon" :src="require(`@/assets/svg/whatsapp.svg`)"></inline-svg> Whatsapp
          </b-button>
        </div>
        <div id="callEmail" v-if="ask"><InterestForm :product="item" @close="ask = false" /></div>
      </div>
      <div class="container mt-2" v-if="item.general_info != undefined">
        <div class="strong">Informações Gerais</div>
        <div class="text"><span v-for="(info, index) in item.general_info" :key="index">{{ info.name }}</span></div>
      </div>
      <div class="container mt-2" v-if="item.more_info != undefined">
        <div class="strong">Desempenho e dimensões</div>
        <div class="text"><span v-for="(info, index) in item.more_info" :key="index">{{ info.name }}: {{ info.value }}</span></div>
      </div>
      <div class="container mt-2" v-if="item.confort != undefined">
        <div class="strong">Conforto</div>
        <div class="text">{{ item.confort }}</div>
      </div>
      <div class="container mt-2" v-if="item.security != undefined">
        <div class="strong">Segurança</div>
        <div class="text">{{ item.security }}</div>
      </div>
    </div>
    <div class="principal hideDesktop column">
      <div class="caracteristics p-0" v-if="item.description != undefined">
        <div class="title">Principais Características</div>
        <div class="description">{{ item.description }}</div>
      </div>
      <div class="table" v-if="item.details != undefined">
          <div class="table-line" v-for="detail, x in item.details" :key="x" :class="(x%2) ? 'odd' : ''">
            <div class="title">{{ detail.name }}</div>
            <div class="description">{{ detail.value }}</div>
          </div>
      </div>
    </div>
  </div>
    </div>

  <div class="content" v-if="notShowThis == 'durvalNaoQuer'">
      <div class="characteristics">Características principais</div>
      <div class="attributes">
          <div v-for="attribute, i in item.attributes" :key="`at${i}`" class="items" :class="(i%2 !=0) ? 'odd' : ''">
              <span class="name">id: {{ attribute.id }} -</span>
              <span class="name">{{ attribute.name }}</span>
              <span v-if="attribute.value != 'Sim'">:</span>
              <span v-if="attribute.value != 'Sim'" class="value">{{ attribute.value }}</span>
          </div>
      </div>  
  </div>

  </div>
</div>
</template>

<script>
import showPrice from '@/components/helpers/showPrice'
import showCondition from '@/components/helpers/showCondition'
import showNumber from '@/components/helpers/showNumber'
import InterestForm from '@/components/interestForm'
import MercadoLivre from "@/components/tools/mercadoLivre";

export default {
  name: "Element",
  mixins: [ showPrice, showCondition, showNumber ],
  components: { InterestForm },
  props: {
    dataUser: Object,
  },
  data(){
    return {
      item: undefined,
      loading: false,
      connection: undefined,
      metaData: {},
      showImage: undefined,
      max_thumbnails: 7,
      ask: false,
      whatsapp: undefined,
      whatsapp_options: { url: "https://api.whatsapp.com/send?phone="},
      show: false,
      notShowThis: undefined,
    };
  },
  async created() {
    this.connection = await new MercadoLivre(this.dataUser.ml_user, this.dataUser.ml_default_category);
    this.loadData();
  },
  methods: {
    openWhatsapp(url){
      window.open(url, '_blank');
    },
    verifyWhatsapp() {
        this.show = false;
        this.whatsapp_options = this.dataUser.options.whatsapp_options;
        if(this.dataUser.whatsapp_active){
        this.dataUser.meliphones.forEach((phone) => {
            if (phone.linktype == 0) {
                var phone_number = phone.number.replace(/\D/g, '');
                this.whatsapp = phone_number;
                this.show = true;
            }
        });
        }
    },
    changeImage(x){
      this.showImage = x
    },
    async loadData() {
      var p = this.$route.path;
      var pathArray = p.split("/");
      var itemID = pathArray[pathArray.length - 1];

      this.loading = true;
      if(this.connection != undefined){
          await this.connection.product(itemID).then((res) => {
            this.item = res
            this.metaData.addTitle = this.item.title;
            this.metaData.description = this.item.description.substring(0, 150) + "...";
            this.metaData.image = this.item.images[0].image;
            this.metaData.addKeywords = this.item.attributes.map(item => item.name).join(",");
            this.changeImage(this.item.images[0].image);
            this.loading = false;
            this.$emit('changeMeta', this.metaData);
          });      
      }
    },
  },
  watch: {
    dataUser: {
      handler(){
        if(this.dataUser != undefined){
          if(this.dataUser.options != undefined) {
            this.verifyWhatsapp();
          }
        }
      },
      immediate: true,
    }
  },
};
</script>


<style lang="scss">

.callEmail {
  width: 100%;
}

.corner div {
  border-radius:6px;
}

#c{
  margin-bottom: 40px;

.hideDesktop {
  display: flex;
  @media (min-width: 920px) { display: none !important; }
}

.hideMobile {
  display: flex;
  @media (max-width: 919px) { display: none !important; }
}

.column {
  flex-direction: column;
}
}

.flex {
  display: flex;
  gap: 24px;

  @media (max-width: 919px) {
    flex-direction: column;
  }
}

.site_details {
  width: 35%;

  @media (max-width: 919px) {
    width: 100%;
  }

  .container {
    gap: 16px !important;

    .text {
        display: flex;
        flex-direction: column;
        font-size: 12px;
    }

    .title{
      font-size: 24px;
      line-height: 29px;
      font-weight: 400;
    }

    .price {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--s-price);
    }

    .meliid {
        font-size: 0.8rem;
        color: var(--s-attributes);
        //display: none;
    }

    .attributes, .condition, .location {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: var(--s-attributes);
        display: flex;
        gap:10px;
    }

    .condition, .location{
      padding: 0 !important;
    }
    .condition {
      color: var(--s-price) !important;
    }

    .actions{
      display: flex;
      gap:12px;
      flex: 1 1 0;

      #email, #whatsapp {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--s-button-text);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        border-radius: 6px;
        min-width: 50%;
      }

      #email {
        background: var(--s-button);
      }

      #email:hover {
        background: var(--s-button-hover);
      }

      #whatsapp {
        background: var(--s-button-green);

      svg {
          width: 25px;
          height: 20px;
          fill: var(--s-button-text);
          position: relative;
          left: -5px;
      }

      }

      #whatsapp:hover {
        background: var(--s-button-green-hover);
      }
      
    }

  }
}


.principal {
  width: 100%;

.table {
    padding: 32px 0 0 0;
}

.table-line {
    display: flex;
    flex: 1 1 0;
    justify-content: space-evenly;
    flex-direction: row;

    .title{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px 24px;
      gap: 10px;
      background: #EBEBEB;
      border-radius: 6px 0px 0px 0px;
      width: 100%;
    }

    .description{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px 24px;
      gap: 10px;
      background: #F5F5F5;
      border-radius: 0px 6px 0px 0px;
      width: 100%;
    }
  }
  .odd {
    .title{
      background: #F5F5F5 !important;
    }
    .description{
      background: #ffffff !important;
    }

}

  .caracteristics {
    padding: 32px 0 0 0;
    font-family: 'Inter';
    color: var(--s-text);

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
    }

    .others {
      padding: 20px 0 0 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
    }

  }


  .previews {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    isolation: isolate;

    .thumbnails{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 10px;

      .thumbnail {
          width: 80px;
          height: 60px;
          border: 1px solid #F43A3C;
          border-radius: 6px;
          background-position: center !important;
          background-size: cover !important;
          overflow: hidden;
      }

      .last_thumbnail {
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            background-color: #ffffff;
            opacity: 0.7;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            align-items: center;
          }
      }
    }

      .preview {
        width: 100%;
        height: 600px;
        left: 90px;
        top: 0px;
        border-radius: 6px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
      }
      
  }

}

</style>